import { useState } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  ButtonGroup,
  Button,
} from '@mui/material'
import ColorizeIcon from '@mui/icons-material/Colorize'
import { connect } from 'react-redux'
import { setBrandColor } from 'state/actions'
import Popover from '@mui/material/Popover'
import { ChromePicker } from 'react-color'
import axios from 'axios'

const Colors = (props: any) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [selectedBrand, setSelectedBrand] = useState(null)
  const [selectedColor, setSelectedColor] = useState('#000000')

  const open = Boolean(anchorEl)

  const onBrandSelect = (event, brand) => {
    setSelectedBrand(brand._id)
    setSelectedColor(brand.color)
    setAnchorEl(event.currentTarget)
  }

  const onPickerClose = () => {
    setSelectedBrand(null)
    setAnchorEl(null)
  }

  const onColorSubmit = async () => {
    try {
      props.setBrandColor(selectedBrand, selectedColor)

      axios.patch(`/admin/brand/${selectedBrand}`, {
        color: selectedColor,
      })

	  onPickerClose()
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        flex: '1',
        marginTop: '8px',
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <h2>Couleurs des Plaques</h2>
        <img className="rainbow" src="/assets/rainbow.png" alt="Rainbow" />
      </div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Plaque</TableCell>
              <TableCell>Couleur</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.brands.map((brand) => (
              <TableRow key={brand._id}>
                <TableCell>{brand.name}</TableCell>
                <TableCell align="center">
                  <div style={{ display: 'flex' }}>
                    <div
                      className="color-view"
                      style={{
                        backgroundColor: `${
                          selectedBrand === brand._id
                            ? selectedColor
                            : brand.color
                        }`,
                        marginTop: '3px',
                      }}
                    ></div>
                    <IconButton
                      size="small"
                      style={{ marginLeft: '4px' }}
                      onClick={(event) => onBrandSelect(event, brand)}
                    >
                      <ColorizeIcon />
                    </IconButton>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={onPickerClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <ChromePicker
          color={selectedColor}
          onChange={(color) => setSelectedColor(color.hex)}
        />
        <ButtonGroup size="small" fullWidth>
          <Button
            style={{ borderTopLeftRadius: 0 }}
            color="inherit"
            variant="contained"
            onClick={onPickerClose}
          >
            Annuler
          </Button>
          <Button
            onClick={onColorSubmit}
            style={{ borderTopRightRadius: 0 }}
            variant="contained"
          >
            Valider
          </Button>
        </ButtonGroup>
      </Popover>
    </div>
  )
}

const mapStateToProps = (state: any) => {
  return {
    brands: state.settings.brands,
  }
}

const mapDispatchToProps = { setBrandColor }

export default connect(mapStateToProps, mapDispatchToProps)(Colors)
