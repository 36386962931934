import DeleteIcon from '@mui/icons-material/Delete'
import UploadIcon from '@mui/icons-material/Upload'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import FormControl from '@mui/material/FormControl'
import IconButton from '@mui/material/IconButton'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import TextField from '@mui/material/TextField'
import DealershipSelector from 'components/DealershipSelector'
import Crop from 'components/Crop'
import getInitials from 'helpers/getInitials'
import { imgurl } from 'helpers/imageUrl'
import removeDuplicates from 'helpers/removeDuplicates'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import { connect } from 'react-redux'
import './UsersView.css'

const roles: any = [
  {
    name: 'Administrateur',
    level: 3,
  },
  {
    name: 'Rédacteur',
    level: 2,
  },
  {
    name: 'Lecteur',
    level: 1,
  },
]

const UserDialog = (props: any) => {
  const [email, setEmail] = useState(
    props.user?.email || (props.placeholder?.email ?? ''),
  )
  const [lastname, setLastname] = useState(
    props.user?.lastname || (props.placeholder?.lastname ?? ''),
  )
  const [firstname, setFirstname] = useState(
    props.user?.firstname || (props.placeholder?.firstname ?? ''),
  )
  const [job, setJob] = useState(props.user?.job || '')
  const [office, setOffice] = useState(
    props.user?.office?.level || props.offices[0]?.level,
  )
  const [role, setRole] = useState(props.user?.role || roles[2].level)
  const [selectedDealerships, setSelectedDealerships] = useState(
    props.user?.dealerships || [],
  )
  const [selectedBrands, setSelectedBrands] = useState(props.user?.brands || [])
  const [grouped, setGrouped] = useState(!!props.user?.group || false)

  const [cropDialogOpen, setCropDialogOpen] = useState(false)
  const [imageFile, setImageFile] = useState('')
  const [imageUrl, setImageUrl] = useState('')
  const [avatar, setAvatar] = useState(props.user?.avatar || '')

  useEffect(() => {
    setEmail(props.user?.email || (props.placeholder?.email ?? ''))
    setLastname(props.user?.lastname || (props.placeholder?.lastname ?? ''))
    setFirstname(props.user?.firstname || (props.placeholder?.firstname ?? ''))
    setJob(props.user?.job || '')
    setOffice(props.user?.office?.level || props.offices[0]?.level)
    setRole(props.user?.role || roles[2].level)
    setSelectedDealerships(props.user?.dealerships || [])
    setSelectedBrands(props.user?.brands || [])
    setGrouped(!!props.user?.group || false)
    setAvatar(props.user?.avatar || '')
  }, [props.user, props.offices, props.placeholder])

  const onFileChange = (event: any) => {
    if (event?.target?.files[0]) {
      setImageFile(event.target.files[0])
      setImageUrl(URL.createObjectURL(event.target.files[0]))
      setCropDialogOpen(true)
      event.target.value = null
    }
  }

  const cancel = () => {
    if (!props.user) {
      setEmail('')
      setLastname('')
      setFirstname('')
      setJob('')
      setOffice(props.offices[0]?.level)
      setRole(roles[0].level)
      setSelectedDealerships([])
      setSelectedBrands([])
      setGrouped(false)
      setAvatar('')
    }

    props.handleClose()
  }

  const onUserFormSubmit = (e: any) => {
    e.preventDefault()

    let brands = selectedBrands.reduce((acc, curr) => {
      if (!!props.brands.find((v) => v._id === curr)) {
        return [...acc, curr]
      }
      return acc
    }, [])

    const data = {
      email,
      lastname,
      firstname,
      job,
      office,
      role,
      dealerships: selectedDealerships,
      brands,
      group: grouped,
      avatar: avatar || undefined,
    }
    if (props.user) {
      props.onUpdate(data, props.user._id)
    } else {
      props.onCreate(data)
    }
  }

  const onGroupCheck = (
    e: any,
    brandIds: Array<string>,
    dealershipIds: Array<string>,
  ) => {
    if (e.target.checked) {
      setGrouped(true)
      setSelectedBrands(brandIds)
      setSelectedDealerships(dealershipIds)
    } else {
      setGrouped(false)
    }
  }

  const onBrandCheck = (
    e: any,
    brandId: string,
    dealershipIds: Array<string>,
  ) => {
    if (e.target.checked) {
      setSelectedDealerships(
        removeDuplicates([...selectedDealerships, ...dealershipIds]),
      )
      setSelectedBrands(removeDuplicates([...selectedBrands, brandId]))
    } else {
      setSelectedDealerships(
        selectedDealerships.filter((v: any) => !dealershipIds.includes(v)),
      )
      setSelectedBrands(selectedBrands.filter((v: any) => v !== brandId))
    }
  }

  const onDealershipCheck = (e: any, dealershipId) => {
    if (e.target.checked) {
      setSelectedDealerships(
        removeDuplicates([...selectedDealerships, dealershipId]),
      )
    } else {
      setSelectedDealerships(
        selectedDealerships.filter((v: any) => v !== dealershipId),
      )
    }
  }

  const onCropSubmit = async (crop) => {
    let url = await props.onUploadImage(imageFile, crop)
    if (url) {
      setAvatar(url)
      setCropDialogOpen(false)
    }
  }

  return (
    <Dialog
      open={props.open}
      onClose={cancel}
      fullWidth
      maxWidth="sm"
      scroll="body"
    >
      <form className="user-dialog-form" onSubmit={onUserFormSubmit}>
        <DialogTitle
          style={{
            display: 'inline-flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          {props.user ? "Modifier l'utilisateur" : 'Créer un utilisateur'}
          {props.user && (
            <IconButton
              size="small"
              onClick={() => props.onDelete(props.user._id)}
            >
              <DeleteIcon />
            </IconButton>
          )}
        </DialogTitle>
        <DialogContent dividers>
          <div className="user-avatar">
            <Avatar
              sx={{ width: 75, height: 75 }}
              alt="Avatar"
              src={imgurl(avatar)}
            >
              {getInitials(`${firstname} ${lastname}`)}
            </Avatar>
            <label htmlFor="button-file">
              <input
                style={{ display: 'none' }}
                accept="image/*"
                id="button-file"
                type="file"
                onChange={onFileChange}
              />
              <Button
                component="span"
                variant="contained"
                startIcon={<UploadIcon />}
                disabled={props.loading}
                style={{ marginLeft: '20px' }}
              >
                Modifier l'avatar
              </Button>
            </label>
          </div>
          <div className="user-dialog-form-row">
            <TextField
              className="text-input"
              fullWidth
              label="Nom"
              variant="standard"
              InputLabelProps={{
                style: {
                  color: '#43425D',
                  fontSize: 16,
                },
                required: false,
              }}
              value={lastname}
              onChange={(e) => setLastname(e.target.value)}
              required
              type="text"
            />
            <TextField
              className="text-input"
              fullWidth
              label="Prénom"
              variant="standard"
              InputLabelProps={{
                style: {
                  color: '#43425D',
                  fontSize: 16,
                },
                required: false,
              }}
              value={firstname}
              onChange={(e) => setFirstname(e.target.value)}
              required
              type="text"
            />
          </div>
          <div className="user-dialog-form-row">
            <TextField
              className="text-input"
              fullWidth
              label="Adresse email"
              variant="standard"
              InputLabelProps={{
                style: {
                  color: '#43425D',
                  fontSize: 16,
                },
                required: false,
              }}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              type="email"
            />
            <TextField
              className="text-input"
              fullWidth
              label="Poste"
              variant="standard"
              InputLabelProps={{
                style: {
                  color: '#43425D',
                  fontSize: 16,
                },
                required: false,
              }}
              value={job}
              onChange={(e) => setJob(e.target.value)}
              type="text"
            />
          </div>
          <div className="user-dialog-form-row">
            <FormControl
              variant="standard"
              style={{ width: '100%', maxWidth: '250px' }}
            >
              <InputLabel style={{ color: '#43425D' }}>Fonction</InputLabel>
              <Select
                className="text-input"
                value={office}
                onChange={(event: SelectChangeEvent) => {
                  setOffice(event.target.value)
                }}
                label="Fonction"
                required
                fullWidth
                size="small"
              >
                {props.offices?.map((v: any) => (
                  <MenuItem key={v._id} value={v.level}>
                    {v.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl
              variant="standard"
              style={{ width: '100%', maxWidth: '250px' }}
            >
              <InputLabel style={{ color: '#43425D' }}>Rôle</InputLabel>
              <Select
                className="text-input"
                value={role}
                onChange={(event: SelectChangeEvent) => {
                  setRole(event.target.value)
                }}
                label="Rôle"
                required
                fullWidth
                size="small"
              >
                {roles.map((v: any) => (
                  <MenuItem key={v.level} value={v.level}>
                    {v.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <h4>Concessions</h4>
          <DealershipSelector
            brands={props.brands}
            selectedDealerships={selectedDealerships}
            selectedBrands={selectedBrands}
            grouped={grouped}
            onGroupCheck={onGroupCheck}
            onBrandCheck={onBrandCheck}
            onDealershipCheck={onDealershipCheck}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={cancel} color="inherit">
            Annuler
          </Button>
          <Button disabled={props.loading} type="submit" color="primary">
            {props.user ? 'Modifier' : 'Créer'}
          </Button>
        </DialogActions>
      </form>
      <Crop
        circularCrop
        loading={props.loading}
        onSubmit={onCropSubmit}
        imageUrl={imageUrl}
        open={cropDialogOpen}
        onClose={() => setCropDialogOpen(false)}
        imageWidth={'700px'}
        defaultCrop={{
          unit: 'px',
          aspect: 1,
          width: 240,
          x: 30,
          y: 30,
        }}
      />
    </Dialog>
  )
}

UserDialog.propTypes = {
  open: PropTypes.bool,
  loading: PropTypes.bool,
  handleClose: PropTypes.func,
  onCreate: PropTypes.func,
  onUpdate: PropTypes.func,
  onDelete: PropTypes.func,
  onUploadImage: PropTypes.func,
  user: PropTypes.object,
  placeholder: PropTypes.object,
  brands: PropTypes.array,
  offices: PropTypes.array,
}

UserDialog.defaultProps = {
  open: false,
  loading: false,
  handleClose: () => null,
  onCreate: () => null,
  onUpdate: () => null,
  onDelete: () => null,
  onUploadImage: () => null,
  user: null,
  placeholder: null,
  brands: [],
  offices: [],
}

const mapStateToProps = (state: any) => {
  return {
    brands: state.settings.mergedBrands,
    offices: state.settings.offices,
  }
}

export default connect(mapStateToProps, null)(UserDialog)
