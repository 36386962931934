import SearchIcon from '@mui/icons-material/Search'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import Chip from '@mui/material/Chip'
import CircularProgress from '@mui/material/CircularProgress'
import InputAdornment from '@mui/material/InputAdornment'
import Pagination from '@mui/material/Pagination'
import Paper from '@mui/material/Paper'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TextField from '@mui/material/TextField'
import axios from 'axios'
import Page from 'components/Page'
import getInitials from 'helpers/getInitials'
import { imgurl } from 'helpers/imageUrl'
import { shortjoin } from 'helpers/shortjoin'
import { useDebouncedEffect } from 'helpers/useDebouncedEffect'
import React, { useCallback, useState } from 'react'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import { updateUser } from 'state/actions'
import UserDialog from '../UsersView/UserDialog'
import '../UsersView/UsersView.css'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  border: 0,
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: '#595959',
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontWeight: '600',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: '14px',
    fontFamily: 'Open Sans',
    fontWeight: '600',
    color: '#595959',
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  cursor: 'pointer',
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
  '&:hover': {
    backgroundColor: '#dddddd !important',
  },
}))

const RequestsView = (props: any) => {
  const [users, setUsers] = useState<any>(null)
  const [page, setPage] = useState(1)
  const [pageCount, setPageCount] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [userDialogOpen, setUserDialogOpen] = useState(false)
  const [userDialogLoading, setUserDialogLoading] = useState(false)
  const [selectedUser, setSelectedUser] = useState(null)

  const getUsers = useCallback(async () => {
    try {
      let response = await axios.get('/admin/user/requests', {
        params: { page: page - 1 },
      })

      setUsers(response?.data?.users?.length ? response.data.users : [])
      if (response?.data?.pagination) {
        setPageCount(
          Math.max(
            Math.ceil(
              response.data.pagination.total / response.data.pagination.limit,
            ),
            1,
          ),
        )
        setPageSize(response.data.pagination.limit)
      }
    } catch (err: any) {
      console.error(err)
      if (err?.response?.data?.error?.message) {
        toast.error(err.response.data.error.message)
      } else {
        toast.error('Erreur serveur, récupération impossible')
      }
    }
  }, [page])

  useDebouncedEffect(getUsers, '', page, 250)

  const onCreate = async (data: any) => {
    try {
      setUserDialogLoading(true)
      await axios.post('/admin/user/', data)

      handleCloseDialog()
      setUserDialogLoading(false)
      getUsers()
    } catch (err: any) {
      console.error(err)
      setUserDialogLoading(false)
      if (err?.response?.data?.error?.message) {
        toast.error(err.response.data.error.message)
      } else {
        toast.error('Erreur serveur, opération impossible')
      }
    }
  }

  const onDelete = async (uid: string) => {
    if (window.confirm('Êtes-vous sûr de vouloir supprimer cet demande ?')) {
      try {
        setUserDialogLoading(true)
        await axios.delete(`/admin/user/request/${uid}`)

        handleCloseDialog()
        setUserDialogLoading(false)
        getUsers()
      } catch (err: any) {
        console.error(err)
        setUserDialogLoading(false)
        if (err?.response?.data?.error?.message) {
          toast.error(err.response.data.error.message)
        } else {
          toast.error('Erreur serveur, opération impossible')
        }
      }
    }
  }

  const onUploadImage = async (imageFile: string, crop: any) => {
    try {
      setUserDialogLoading(true)
      const data = new FormData()

      data.append('file', imageFile)
      data.append(
        'body',
        JSON.stringify({
          resize: {
            width: 701,
          },
          crop: {
            top: Math.round(crop.y),
            left: Math.round(crop.x),
            width: Math.round(crop.width),
            height: Math.round(crop.height),
          },
        }),
      )

      let response = await axios.put('/upload/image', data)

      if (response?.data?.file) {
        setUserDialogLoading(false)
        return response.data.file
      }
    } catch (err: any) {
      console.error(err)
      setUserDialogLoading(false)
      if (err?.response?.data?.error?.message) {
        toast.error(err.response.data.error.message)
      } else {
        toast.error('Erreur serveur, opération impossible')
      }
    }
  }

  const handleCloseDialog = () => {
    setUserDialogOpen(false)
    setTimeout(() => setSelectedUser(null), 250)
  }

  const handlePaginationChange = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    setPage(value)
  }

  const padArray = (arr: Array<any>, len: number, fill: any) => {
    if (!arr || arr.length >= len) {
      return arr
    }
    return arr.concat(Array(len).fill(fill)).slice(0, len)
  }

  const renderTable = () => (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow style={{ boxShadow: '0px 1px 4px 3px rgba(0, 0, 0, 0.1)' }}>
            <StyledTableCell>Nom</StyledTableCell>
            <StyledTableCell>Prénom</StyledTableCell>
            <StyledTableCell>Email</StyledTableCell>
            <StyledTableCell>Note</StyledTableCell>
            <StyledTableCell>Supprimer</StyledTableCell>
            <StyledTableCell>Créer</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {padArray(users, pageSize, null).map((user: any, i: number) => {
            if (!user) {
              return (
                <StyledTableRow
                  style={{ cursor: 'auto', height: '56px' }}
                  key={i}
                >
                  <StyledTableCell />
                  <StyledTableCell />
                  <StyledTableCell />
                  <StyledTableCell />
                  <StyledTableCell />
                  <StyledTableCell />
                </StyledTableRow>
              )
            }
            return (
              <StyledTableRow key={user._id}>
                <StyledTableCell>{user.lastname}</StyledTableCell>
                <StyledTableCell>{user.firstname}</StyledTableCell>
                <StyledTableCell>{user.email}</StyledTableCell>
                <StyledTableCell>{user.note}</StyledTableCell>

                <StyledTableCell>
                  {' '}
                  <Button
                    color="error"
                    onClick={() => {
                      onDelete(user._id)
                    }}
                    variant="contained"
                  >
                    Supprimer
                  </Button>
                </StyledTableCell>
                <StyledTableCell>
                  {' '}
                  <Button
                    onClick={() => {
                      setSelectedUser(user)
                      setUserDialogOpen(true)
                    }}
                    variant="contained"
                  >
                    Créer
                  </Button>
                </StyledTableCell>
              </StyledTableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )

  return (
    <Page title="Utilisateurs" className="users-container">
      {!users ? (
        <CircularProgress />
      ) : (
        <>
          {renderTable()}
          <Pagination
            classes={{ root: 'users-pagination' }}
            count={pageCount}
            page={page}
            onChange={handlePaginationChange}
          />
        </>
      )}
      <UserDialog
        open={userDialogOpen}
        loading={userDialogLoading}
        handleClose={handleCloseDialog}
        placeholder={selectedUser}
        onCreate={onCreate}
        onUploadImage={onUploadImage}
      />
    </Page>
  )
}

const mapStateToProps = (state: any) => {
  return {
    user: state.auth.user,
  }
}

const mapDispatchToProps = {
  updateUser,
}

export default connect(mapStateToProps, mapDispatchToProps)(RequestsView)
