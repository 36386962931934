import React, { useState } from 'react'
import { connect } from 'react-redux'
import { login, updateSettings } from 'state/actions'
import Page from 'components/Page'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import axios from 'axios'

import './LoginView.css'

const LoginView = (props: any) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [rememberChecked, setRememberChecked] = useState(false)

  const getSettings = async () => {
    try {
      const [officeResponse, brandResponse, dealershipResponse] =
        await Promise.all([
          axios.get('/admin/office'),
          axios.get('/admin/brand'),
          axios.get('/admin/dealership'),
        ])

      return {
        offices: officeResponse?.data?.offices,
        brands: brandResponse?.data?.brands,
        dealerships: dealershipResponse?.data?.dealerships,
      }
    } catch (err) {
      console.error(err)
    }
  }

  const onSubmit = async (e: any) => {
    e.preventDefault()

    //Manage loading

    try {
      let response = await axios.post('/auth/signin', { email, password })

      localStorage.setItem('token', response.data.token)
      axios.defaults.headers.common[
        'Authorization'
      ] = `Bearer ${response.data.token}`
      let settings = await getSettings()
      props.updateSettings(
        settings.offices,
        settings.brands,
        settings.dealerships,
      )
      props.login(response.data.token, response.data.user)
    } catch (err: any) {
      console.error(err)
      if (err?.response?.data?.error?.message) {
        toast.error(err.response.data.error.message)
      } else {
        toast.error('Erreur serveur, connexion impossible')
      }
    }
  }

  return (
    <Page title="Connexion" className="login-container">
      <div className="login-bg-image">
        <img
          id="login-bg"
          src="/assets/login-bg.jpg"
          alt="Connexion"
          title="Connexion"
        />
        <img id="logo" src="/assets/logo-jra.png" alt="Logo" title="Logo" />
      </div>
      <div className="login-form-container">
        <h1>CONNEXION</h1>
        <p>Bienvenue ! Connectez-vous pour ajouter un article :</p>
        <form onSubmit={onSubmit}>
          <TextField
            className="text-input"
            fullWidth
            label="Nom d'utilisateur"
            variant="standard"
            InputLabelProps={{
              style: {
                color: '#43425D',
                fontSize: 16,
              },
              required: false,
            }}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            type="email"
          />
          <TextField
            className="text-input"
            fullWidth
            label="Mot de passe"
            variant="standard"
            InputLabelProps={{
              style: {
                color: '#43425D',
                fontSize: 16,
              },
              required: false,
            }}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            type="password"
          />
          <div className="login-form-row">
            <FormControlLabel
              control={
                <Checkbox
                  style={{ paddingRight: 7 }}
                  size="small"
                  checked={rememberChecked}
                  onChange={(e) => setRememberChecked(e.target.checked)}
                />
              }
              label="Se souvenir de moi"
            />
            <Link to="/auth/reset">Mot de passe oublié ?</Link>
          </div>
          <Button type="submit" variant="contained">
            Connexion
          </Button>
        </form>
      </div>
      {/* <ToastContainer theme="colored" bodyClassName="toast-body" /> */}
    </Page>
  )
}

const mapDispatchToProps = { login, updateSettings }

export default connect(null, mapDispatchToProps)(LoginView)
