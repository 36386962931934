import {
	Search as SearchIcon,
	ThumbUp as ThumbUpIcon,
} from '@mui/icons-material'
import Avatar from '@mui/material/Avatar'
import Chip from '@mui/material/Chip'
import CircularProgress from '@mui/material/CircularProgress'
import InputAdornment from '@mui/material/InputAdornment'
import Pagination from '@mui/material/Pagination'
import Paper from '@mui/material/Paper'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TextField from '@mui/material/TextField'
import axios from 'axios'
import Page from 'components/Page'
import getInitials from 'helpers/getInitials'
import { imgurl } from 'helpers/imageUrl'
import { shortjoin } from 'helpers/shortjoin'
import { useDebouncedEffect } from 'helpers/useDebouncedEffect'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import React, { useCallback, useState } from 'react'
import { toast } from 'react-toastify'
import './HomeView.css'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import { connect } from 'react-redux'
import { sortBy } from 'lodash'


const StyledTableCell = styled(TableCell)(({ theme }) => ({
	border: 0,
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: theme.palette.common.white,
		color: '#595959',
		fontFamily: 'Montserrat',
		fontSize: '16px',
		fontWeight: '600',
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: '14px',
		fontFamily: 'Open Sans',
		fontWeight: '600',
		color: '#595959',
	},
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	cursor: 'pointer',
	'&:nth-of-type(even)': {
		backgroundColor: theme.palette.action.hover,
	},
	// hide last border
	'&:last-child td, &:last-child th': {
		border: 0,
	},
	'&:hover': {
		backgroundColor: '#dddddd !important',
	},
}))

const status: any = {
	3: {
		name: 'Supprimé',
		color: 'error',
	},
	2: {
		name: 'Publié',
		color: 'success',
	},
	1: {
		name: 'Programmé',
		color: 'primary',
	},
	0: {
		name: 'Brouillon',
		color: 'warning',
	},
}

const HomeView = (props: any) => {
	const [articles, setArticles] = useState<any>(null)
	const [query, setQuery] = useState('')
	const [brandFilter, setBrandFilter] = useState(undefined)
	const [dealershipFilter, setDealershipFilter] = useState(undefined)
	const [page, setPage] = useState(1)
	const [pageCount, setPageCount] = useState(1)
	const [pageSize, setPageSize] = useState(10)

	const navigate = useNavigate()

	const getArticles = useCallback(async () => {
		try {
			let response = await axios.get('/admin/article', {
				params: {
					query,
					brand: brandFilter || undefined,
					dealership: dealershipFilter || undefined,
					page: page - 1
				},
			})

			setArticles(
				response?.data?.articles?.length ? response.data.articles : [],
			)
			if (response?.data?.pagination) {
				setPageCount(
					Math.max(
						Math.ceil(
							response.data.pagination.total / response.data.pagination.limit,
						),
						1,
					),
				)
				setPageSize(response.data.pagination.limit)
			}
		} catch (err: any) {
			console.error(err)
			if (err?.response?.data?.error?.message) {
				toast.error(err.response.data.error.message)
			} else {
				toast.error('Erreur serveur, récupération impossible')
			}
		}
	}, [query, brandFilter, dealershipFilter, page])

	useDebouncedEffect(getArticles, query, page, 250)

	const handleQueryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setQuery(event.target.value)
		setPage(1)
	}

	const handlePaginationChange = (
		event: React.ChangeEvent<unknown>,
		value: number,
	) => {
		setPage(value)
	}

	const padArray = (arr: Array<any>, len: number, fill: any) => {
		if (!arr || arr.length >= len) {
			return arr
		}
		return arr.concat(Array(len).fill(fill)).slice(0, len)
	}

	return (
		<Page title="Accueil" className="articles-container">
			{articles ? (
				<>
					<div
						style={{
							width: '100%',
							display: 'flex',
							marginBottom: '20px',
						}}
					>
						<TextField
							label="Rechercher un article"
							type="search"
							style={{
								width: '270px',
							}}
							size="small"
							placeholder='Saisissez au moins un mot'
							value={query}
							onChange={handleQueryChange}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<SearchIcon />
									</InputAdornment>
								),
							}}
						/>
						<FormControl size='small' style={{ minWidth: '180px', marginLeft: '16px' }}>
							<InputLabel>Filtrer par plaque</InputLabel>
							<Select
								label="Filtrer par plaque"
								size="small"
								value={brandFilter}
								onChange={e => setBrandFilter(e.target.value)}
							>
								<MenuItem value={undefined}>
									<em>Aucune</em>
								</MenuItem>
								{props.brands.map((brand: any) => (
									<MenuItem key={brand._id} value={brand._id}>
										{brand.name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
						<FormControl size='small' style={{ minWidth: '210px', marginLeft: '16px' }}>
							<InputLabel>Filtrer par concession</InputLabel>
							<Select
								label="Filtrer par concession"
								size="small"
								value={dealershipFilter}
								onChange={e => setDealershipFilter(e.target.value)}
							>
								<MenuItem value={undefined}>
									<em>Aucune</em>
								</MenuItem>
								{sortBy(props.dealerships, 'name').map((dealership: any) => (
									<MenuItem key={dealership._id} value={dealership._id}>
										{dealership.name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</div>
					<TableContainer component={Paper}>
						<Table sx={{ minWidth: 700 }} aria-label="customized table">
							<TableHead>
								<TableRow
									style={{ boxShadow: '0px 1px 4px 3px rgba(0, 0, 0, 0.1)' }}
								>
									<StyledTableCell>Titre</StyledTableCell>
									<StyledTableCell>Description</StyledTableCell>
									<StyledTableCell>Auteur/autrice</StyledTableCell>
									<StyledTableCell>Plaque(s)</StyledTableCell>
									<StyledTableCell>Concession(s)</StyledTableCell>
									<StyledTableCell>Créé le</StyledTableCell>
									<StyledTableCell>Statut</StyledTableCell>
									<StyledTableCell padding='checkbox'>
										<ThumbUpIcon />
									</StyledTableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{padArray(articles, pageSize, null).map(
									(article: any, i: number) => {
										if (!article) {
											return (
												<StyledTableRow
													style={{ cursor: 'auto', height: '56px' }}
													key={i}
												>
													<StyledTableCell />
													<StyledTableCell />
													<StyledTableCell />
													<StyledTableCell />
													<StyledTableCell />
													<StyledTableCell />
													<StyledTableCell />
													<StyledTableCell />
												</StyledTableRow>
											)
										}
										return (
											<StyledTableRow
												onClick={() => {
													navigate(`/article/${article._id}`)
												}}
												hover
												key={article._id}
											>
												<StyledTableCell>
													<span
														className="article-table-elipsis"
														style={{
															maxWidth: '200px',
														}}
													>
														{article.title || '-'}
													</span>
												</StyledTableCell>
												<StyledTableCell>
													<span
														className="article-table-elipsis"
														style={{
															maxWidth: '250px',
														}}
													>
														{article.description || '-'}
													</span>
												</StyledTableCell>
												<StyledTableCell
													style={{ paddingTop: 0, paddingBottom: 0 }}
												>
													<div
														style={{ display: 'flex', alignItems: 'center' }}
													>
														<Avatar
															sx={{ width: 33, height: 33 }}
															alt="Avatar"
															src={imgurl(article.writer.avatar)}
														>
															<span style={{ fontSize: '13px' }}>
																{getInitials(
																	`${article.writer.firstname} ${article.writer.lastname}`,
																)}
															</span>
														</Avatar>
														<p style={{ marginLeft: 10 }}>
															{article.writer.firstname}{' '}
															{article.writer.lastname.toUpperCase()}
														</p>
													</div>
												</StyledTableCell>
												<StyledTableCell>
													{article.brands.length === 0 ? '-' : shortjoin(article.brands, 2)}
												</StyledTableCell>
												<StyledTableCell align="center">{article.dealerships}</StyledTableCell>
												<StyledTableCell>
													{moment(article.createdAt).format('L')}
												</StyledTableCell>
												<StyledTableCell>
													<Chip
														size="small"
														label={status[article.status]?.name}
														color={status[article.status]?.color || 'default'}
														style={{
															backgroundColor:
																status[article.status]?.color === 'success'
																	? '#66BB6A'
																	: undefined,
														}}
													/>
												</StyledTableCell>
												<StyledTableCell>
													{article.likes.count}
												</StyledTableCell>
											</StyledTableRow>
										)
									},
								)}
							</TableBody>
						</Table>
					</TableContainer>
					<Pagination
						classes={{ root: 'articles-pagination' }}
						count={pageCount}
						page={page}
						onChange={handlePaginationChange}
					/>
				</>
			) : (
				<CircularProgress />
			)}
			{/* <ToastContainer theme="colored" bodyClassName="toast-body" /> */}
		</Page>
	)
}

const mapStateToProps = (state: any) => {
	return {
		brands: state.settings.brands,
		dealerships: state.settings.dealerships,
	}
}

export default connect(mapStateToProps)(HomeView)
