import store from 'state/store'
import { toast } from 'react-toastify'

class ImageUploader {
  constructor(quill, options) {
    this.quill = quill
    this.options = options
    this.range = null

    var toolbar = this.quill.getModule('toolbar')
    toolbar.addHandler('image', this.selectLocalImage.bind(this))
  }

  selectLocalImage() {
    this.range = this.quill.getSelection()
    this.fileHolder = document.createElement('input')
    this.fileHolder.setAttribute('type', 'file')
    this.fileHolder.setAttribute('accept', 'image/*')
    this.fileHolder.setAttribute('style', 'visibility:hidden')

    this.fileHolder.onchange = this.fileChanged.bind(this)

    document.body.appendChild(this.fileHolder)

    this.fileHolder.click()

    window.requestAnimationFrame(() => {
      document.body.removeChild(this.fileHolder)
    })
  }

  readAndUploadFile(file) {
    store.dispatch({
      type: 'SET_CROP',
      show: true,
      imageUrl: URL.createObjectURL(file),
    })

    const unsubscribe = store.subscribe(() => {
      const crop = store.getState().crop

      if (!crop.show) {
        unsubscribe()
      } else if (crop.show && crop.value) {
        store.dispatch({
          type: 'SET_CROP',
          show: false,
        })

        toast.promise(
          this.options
            .upload(file, crop.value)
            .then((imageUrl) => this.insertToEditor(imageUrl)),
          {
            pending: 'Ajout du fichier en cours...',
            error: 'Une erreur inattendue est survenu !',
            success: 'Fichier ajouté avec succès !',
          },
        )
      }
    })
  }

  fileChanged() {
    const file = this.fileHolder.files[0]
    this.readAndUploadFile(file)
  }

  insertToEditor(url) {
    const range = this.range
    // Delete the placeholder image
    this.quill.deleteText(range.index, 1, 'user')
    // Insert the server saved image
    this.quill.insertEmbed(range.index, 'image', `${url}`, 'user')

    range.index++
    this.quill.setSelection(range, 'user')
  }
}

window.ImageUploader = ImageUploader
export default ImageUploader
