import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import Collapse from '@mui/material/Collapse'
import FormControlLabel from '@mui/material/FormControlLabel'
import removeDuplicates from 'helpers/removeDuplicates'
import PropTypes from 'prop-types'
import React, { useState } from 'react'

const DealershipSelector = (props: any) => {
	const [showedBrands, setShowedBrands] = useState<any[]>([])

	const renderBrands = () => {
		const items = props.brands?.map((brand: any) => {
			const checkCount = brand.dealerships.filter((dealership: any) =>
				props.selectedDealerships.includes(dealership._id),
			).length

			const isShowed = showedBrands.includes(brand._id)

			let isLocked = false

			if (props.enabledBrands) {
				isLocked = !props.enabledBrands.includes(brand._id)
			}

			return (
				<React.Fragment key={brand._id}>
					<Checkbox
						size={props.size}
						checked={props.selectedBrands.includes(brand._id)}
						indeterminate={
							checkCount > 0 && !props.selectedBrands.includes(brand._id)
						}
						onChange={(e: any) => {
							const dealershipIds = brand.dealerships.map(
								(dealership: any) => dealership._id,
							)

							props.onBrandCheck(e, brand._id, dealershipIds)
						}}
						disabled={props.grouped || isLocked}
					/>
					<span
						onClick={() => {
							if (!isShowed) {
								setShowedBrands(removeDuplicates([...showedBrands, brand._id]))
							} else {
								setShowedBrands(
									showedBrands.filter((v: any) => v !== brand._id),
								)
							}
						}}
						style={{
							cursor: 'pointer',
							display: 'inline-flex',
							alignItems: 'center',
							opacity: isLocked ? 0.5 : 1
						}}
					>
						{brand.name}
						{
							brand.dealerships.length > 0 ? <ExpandMoreIcon
								style={{ transform: isShowed ? 'rotate(180deg)' : undefined }}
							/> : <></>
						}

					</span>
					<Collapse in={showedBrands.includes(brand._id)} collapsedSize={0}>
						<Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
							{brand.dealerships.map((dealership: any) => {
								return (
									<FormControlLabel
										key={dealership._id}
										label={dealership.name}
										style={{ opacity: isLocked ? 0.5 : 1 }}
										control={<Checkbox size={props.size} />}
										checked={
											!!props.selectedDealerships.includes(dealership._id)
										}
										onChange={(e: any) =>
											props.onDealershipCheck(e, dealership._id)
										}
										disabled={
											props.selectedBrands.includes(brand._id) || props.grouped || isLocked
										}
									/>
								)
							})}
						</Box>
					</Collapse>
				</React.Fragment>
			)
		})

		return items
	}

	return (
		<div style={{ ...props.style, width: '100%' }}>
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					marginBottom: '8px',
				}}
			>
				<Checkbox
					size={props.size}
					checked={props.grouped}
					onChange={(e: any) => {
						let brandIds = []
						let dealershipIds = []

						props.brands.forEach((brand) => {
							brandIds.push(brand._id)
							dealershipIds = dealershipIds.concat(
								brand.dealerships.map((dealership) => dealership._id),
							)
						})

						props.onGroupCheck(e, brandIds, dealershipIds)
					}}
					disabled={props.enableGroup === false}
				/>
				<span style={{ opacity: props.enableGroup === false ? 0.5 : 1 }}>Groupe</span>
			</div>
			{renderBrands()}
		</div>
	)
}

DealershipSelector.propTypes = {
	// Merged brands
	brands: PropTypes.array,
	grouped: PropTypes.bool,
	selectedBrands: PropTypes.array,
	selectedDealerships: PropTypes.array,
	onGroupCheck: PropTypes.func,
	onBrandCheck: PropTypes.func,
	onDealershipCheck: PropTypes.func,
	size: PropTypes.string,
	style: PropTypes.object,
	enabledBrands: PropTypes.array,
	enableGroup: PropTypes.bool
}

DealershipSelector.defaultProps = {
	brands: [],
	grouped: false,
	selectedDealerships: [],
	selectedBrands: [],
	onGroupCheck: () => null,
	onBrandCheck: () => null,
	onDealershipCheck: () => null,
	size: 'medium',
	style: {},
	enabledBrands: null,
	enableGroup: null,
}

export default DealershipSelector
