import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'

const Page = ({
  children,
  className,
  title = 'Groupe Jean Rouyer',
  ...rest
}: any) => {
  return (
    <div className={className} {...rest}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
    </div>
  )
}

Page.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  title: PropTypes.string,
}

export default Page
