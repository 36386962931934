import ReactQuill, { Quill } from 'react-quill'
import Page from 'components/Page'
import CircularProgress from '@mui/material/CircularProgress'
import TextField from '@mui/material/TextField'
import { useState, useRef, useEffect } from 'react'
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import DeleteIcon from '@mui/icons-material/Delete'
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined'
import GroupIcon from '@mui/icons-material/Group'
import CheckBox from '@mui/material/Checkbox'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import DealershipSelector from 'components/DealershipSelector'
import removeDuplicates from 'helpers/removeDuplicates'
import { useNavigate, useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import { setCrop } from 'state/actions'
import axios from 'axios'
import { toast } from 'react-toastify'
import Crop from 'components/Crop'
import NotificationDialog from './NotificationDialog'
import ProgramDialog from './ProgramDialog'
import PreviewDialog from './PreviewDialog'
import ImageUploader from 'helpers/quillUploader'
import { ClickableLink } from 'helpers/quillClickable'
import urlParser from 'js-video-url-parser'
import 'js-video-url-parser/lib/provider/youtube'

import 'react-quill/dist/quill.snow.css'
import './ArticleView.css'
import config from 'config'

const BlockEmbed = Quill.import('blots/block/embed')
class PDFBlot extends BlockEmbed {
  static create(value) {
    let node = super.create()
    node.innerHTML = `<a class="pdf" href="${value}" target="_blank">Voir le PDF</a>`
    node.style = 'display: flex; justify-content: center;'
    node.setAttribute('data-url', value)
    node.setAttribute('contenteditable', false)
    return node
  }
  static value(node) {
    return node.getAttribute('data-url')
  }
}
PDFBlot.blotName = 'pdf'
PDFBlot.tagName = 'pdf'

const icons = Quill.import('ui/icons')
icons[
  'pdf'
] = `<svg style="transform: translate(-5px, 0px)" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="mdi-file-pdf" width="24" height="24" viewBox="0 0 24 24"><path d="M13,9H18.5L13,3.5V9M6,2H14L20,8V20A2,2 0 0,1 18,22H6A2,2 0 0,1 4,20V4A2,2 0 0,1 6,2M10.1,11.4C10.08,11.44 9.81,13.16 8,16.09C8,16.09 4.5,17.91 5.33,19.27C6,20.35 7.65,19.23 9.07,16.59C9.07,16.59 10.89,15.95 13.31,15.77C13.31,15.77 17.17,17.5 17.7,15.66C18.22,13.8 14.64,14.22 14,14.41C14,14.41 12,13.06 11.5,11.2C11.5,11.2 12.64,7.25 10.89,7.3C9.14,7.35 9.8,10.43 10.1,11.4M10.91,12.44C10.94,12.45 11.38,13.65 12.8,14.9C12.8,14.9 10.47,15.36 9.41,15.8C9.41,15.8 10.41,14.07 10.91,12.44M14.84,15.16C15.42,15 17.17,15.31 17.1,15.64C17.04,15.97 14.84,15.16 14.84,15.16M7.77,17C7.24,18.24 6.33,19 6.1,19C5.87,19 6.8,17.4 7.77,17M10.91,10.07C10.91,10 10.55,7.87 10.91,7.92C11.45,8 10.91,10 10.91,10.07Z" /></svg>`
icons[
  'color'
] = `<svg style="width:24px;height:24px" viewBox="0 0 32 32"><path fill="currentColor" d="M6.92,19L5,17.08L13.06,9L15,10.94M20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L13.84,6.41L11.91,4.5L10.5,5.91L11.92,7.33L3,16.25V21H7.75L16.67,12.08L18.09,13.5L19.5,12.09L17.58,10.17L20.7,7.05C21.1,6.65 21.1,6 20.71,5.63Z" /></svg>`

Quill.register(PDFBlot)
Quill.register('modules/imageUploader', ImageUploader)
Quill.register('modules/clickable', ClickableLink)

const modules = {
  clickable: true,
  toolbar: [
    [{ header: '1' }, { header: '2' }],
    ['bold', 'italic', { color: ['', '#e60000'] }],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['link', 'image', 'video', 'pdf'],
  ],
  clipboard: {
    matchers: [
      [
        Node.TEXT_NODE,
        (node: any, delta: any) => {
          try {
            const video = urlParser.parse(node.data)
            if (video.provider === 'youtube' && video.mediaType === 'video') {
              delta.ops = [
                {
                  insert: {
                    video: `https://www.youtube.com/embed/${video.id}?showinfo=0`,
                  },
                },
              ]
            }
          } catch (ex) {}
          return delta
        },
      ],
      [
        Node.ELEMENT_NODE,
        (node: any, delta: any) => {
          delta.ops = delta.ops.reduce((acc: any[], op: any) => {
            if (!op.insert) return acc

            if (typeof op.insert === 'string')
              return [...acc, { insert: op.insert }]
            if (typeof op.insert === 'object' && op.insert.video)
              return [...acc, { insert: op.insert }]

            return acc
          }, [])
          return delta
        },
      ],
    ],
  },
  imageUploader: {
    upload: async (file, crop) => {
      const formData = new FormData()

      const ratio = 1080 / 700

      formData.append('file', file)
      formData.append(
        'body',
        JSON.stringify({
          resize: {
            width: 1081,
          },
          crop: {
            top: Math.round(crop.y * ratio),
            left: Math.round(crop.x * ratio),
            width: Math.round(crop.width * ratio),
            height: Math.round(crop.height * ratio),
          },
        }),
      )

      const res = await axios.put('/upload/image', formData)

      return `${config.API_URL}${res.data.file}`
    },
  },
}

const ArticleView = (props: any) => {
  const [loading, setLoading] = useState(true)

  const [article, setArticle] = useState(null)

  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')

  const [notificationChecked, setNotificationChecked] = useState(false)
  const [selectedDealerships, setSelectedDealerships] = useState([])
  const [selectedBrands, setSelectedBrands] = useState([])
  const [grouped, setGrouped] = useState(false)

  const [office, setOffice] = useState<number>(null)

  const [programDialogOpen, setProgramDialogOpen] = useState(false)
  const [notificationDialogOpen, setNotificationDialogOpen] = useState(false)
  const [previewDialog, setPreviewDialog] = useState(false)

  const [newProgram, setNewProgram] = useState(null)

  const quill = useRef(null)
  const params = useParams()
  const navigate = useNavigate()

  const colors = [
    '',
    '#008EC8',
    '#40403a',
    '#373F45',
    '#1f3855',
    '#00000000',
    '#00000000',
  ]
  const userBrands = props.brands.filter((brand) =>
    props.user.brands.includes(brand._id),
  )
  for (let brand of userBrands) {
    colors.push(brand.color)
  }
  ;(modules.toolbar[1][2] as any).color = colors

  const getArticle = async (articleId: string) => {
    try {
      const res = await axios.get(`/admin/article/${articleId}`)

      setArticle(res.data)
      setTitle(res.data.title)
      setDescription(res.data.description)
      setNotificationChecked(!!res.data.notification)
      setSelectedDealerships(res.data.dealerships)
      setSelectedBrands(res.data.brands)
      setGrouped(res.data.group)
      setOffice(res.data.office)
    } catch (err) {
      console.error(err)
      if (err?.response?.data?.error?.message) {
        toast.error(err.response.data.error.message)
      } else {
        toast.error('Erreur serveur, récupération impossible')
      }
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (quill.current != null) {
      const editor = quill.current.getEditor()
      const toolbar = editor.getModule('toolbar')

      toolbar.addHandler('pdf', () => {
        let input = document.createElement('input')
        input.type = 'file'
        input.accept = 'application/pdf'
        input.onchange = (_) => {
          const file = input.files[0]

          toast.promise(
            async () => {
              const formData = new FormData()
              formData.append('file', file)

              const res = await axios.put('/upload/pdf', formData)

              const editor = quill.current.getEditor()
              editor.insertEmbed(
                editor.getSelection(true).index,
                'pdf',
                `${config.API_URL}${res.data.file}`,
              )
            },
            {
              pending: 'Ajout du fichier en cours...',
              error: 'Une erreur inattendue est survenu !',
              success: 'Fichier ajouté avec succès !',
            },
          )
        }
        input.click()
      })
    }
  })

  useEffect(() => {
    if (params.id) {
      getArticle(params.id)
    } else {
      if (!article) {
        setLoading(false)
      } else {
        window.location.href = '/article'
      }
    }
  }, [params])

  const onGroupCheck = (
    e: any,
    brandIds: Array<string>,
    dealershipIds: Array<string>,
  ) => {
    if (e.target.checked) {
      setGrouped(true)
      setSelectedBrands(brandIds)
      setSelectedDealerships(dealershipIds)
    } else {
      setGrouped(false)
      setSelectedBrands([])
      setSelectedDealerships([])
    }
  }

  const onBrandCheck = (
    e: any,
    brandId: string,
    dealershipIds: Array<string>,
  ) => {
    if (e.target.checked) {
      setSelectedDealerships(
        removeDuplicates([...selectedDealerships, ...dealershipIds]),
      )
      setSelectedBrands(removeDuplicates([...selectedBrands, brandId]))
    } else {
      setSelectedDealerships(
        selectedDealerships.filter((v: any) => !dealershipIds.includes(v)),
      )
      setSelectedBrands(selectedBrands.filter((v: any) => v !== brandId))
    }
  }

  const onSubmit = async (
    type: 'publish' | 'program' | 'draft',
    notifImage = null,
    notifYoutube = null,
    notifTitle = null,
    notifDescription = null,
  ) => {
    try {
      const content = quill.current.getEditor().getContents().ops

      const body: any = {
        title: title,
        description: description,
        content: content,
        office: office || undefined,
        group: grouped,
        youtube: notifYoutube && notifYoutube.length != 0 ? notifYoutube : null,
        brands: selectedBrands,
        dealerships: selectedDealerships,
        draft: type === 'draft',
        programmedAt:
          type === 'program' && newProgram ? newProgram.toDate() : undefined,
      }

      if (notifImage || article?.thumbnail) {
        body.thumbnail = notifImage || article?.thumbnail
      }

      if (notificationChecked) {
        if (type === 'draft') {
          body.notification = article?.notification
        } else {
          body.notification = {
            image: notifImage || article?.thumbnail,
            title: notifTitle || '',
            description: notifDescription || '',
          }
        }
      }

      if (article) {
        await axios.put(`/admin/article/${article._id}`, body)

        navigate('/')
      } else {
        await axios.post('/admin/article', body)

        navigate('/')
      }

      switch (type) {
        case 'draft':
          toast.info(
            `Votre article a bien été ${article ? 'mis à jour' : 'enregistré'}`,
            {
              containerId: 'app-root',
            },
          )
          break
        case 'program':
          toast.info('Votre article a bien été enregistré et programmé', {
            containerId: 'app-root',
          })
          break
        case 'publish':
          toast.success('Votre article a bien été enregistré et publié', {
            containerId: 'app-root',
          })
          break
      }
    } catch (err) {
      console.error(err)
      if (err?.response?.data?.error?.message) {
        toast.error(err.response.data.error.message)
      } else {
        toast.error('Erreur serveur, opération impossible')
      }
    }
  }

  const onDelete = async () => {
    if (window.confirm('Êtes-vous sûr de vouloir supprimer cet article ?')) {
      if (article !== null) {
        try {
          await axios.delete(`/admin/article/${article._id}`)
          toast.info('Votre article a bien été supprimé', {
            containerId: 'app-root',
          })
        } catch (err: any) {
          console.error(err)
          if (err?.response?.data?.error?.message) {
            toast.error(err.response.data.error.message)
          } else {
            toast.error('Erreur serveur, opération impossible')
          }
        }
      }

      navigate('/')
    }
  }

  const onDealershipCheck = (e: any, dealershipId) => {
    if (e.target.checked) {
      setSelectedDealerships(
        removeDuplicates([...selectedDealerships, dealershipId]),
      )
    } else {
      setSelectedDealerships(
        selectedDealerships.filter((v: any) => v !== dealershipId),
      )
    }
  }

  const onProgram = (date) => {
    setNewProgram(date)
    setProgramDialogOpen(false)
    setNotificationDialogOpen(true)
  }

  const canSubmit = () =>
    title.length > 0 &&
    description.length > 0 &&
    office != null &&
    selectedDealerships.length > 0

  return (
    <Page title="Article" className="article-container">
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <div
            style={{
              flex: 1,
              marginBottom: '5rem',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <TextField
              style={{ marginBottom: '14px' }}
              placeholder="Saisissez le titre"
              variant="outlined"
              autoComplete="none"
              required
              inputProps={{ maxLength: 120 }}
              helperText={`${title.length}/120`}
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            <TextField
              style={{ marginBottom: '4px' }}
              placeholder="Saisissez le texte d'introduction ici"
              variant="outlined"
              rows={3}
              multiline
              autoComplete="none"
              required
              inputProps={{ maxLength: 400 }}
              helperText={`${description.length}/400`}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
            <Button
              onClick={() =>
                setPreviewDialog(quill.current.getEditor().getContents())
              }
              style={{ alignSelf: 'flex-end', marginBottom: '6px' }}
              variant="text"
            >
              Prévisualiser
            </Button>
            <div className="article-editor">
              <ReactQuill
                ref={quill}
                theme="snow"
                modules={modules}
                placeholder="Saisissez le contenu de l'article ici"
                defaultValue={{ ops: article?.content || [] } as any}
                scrollingContainer={document.documentElement}
              />
            </div>
          </div>
          <div style={{ marginLeft: '20px', maxWidth: '400px' }}>
            <Paper className="article-actions-container">
              <div style={{ display: 'flex', marginBottom: '8px' }}>
                <h4>Publier / enregistrer l'article</h4>
              </div>
              <div style={{ display: 'flex', marginBottom: '8px' }}>
                <Button
                  size="small"
                  style={{ minWidth: '120px' }}
                  variant="contained"
                  color="primary"
                  disabled={!canSubmit() || article?.status === 2}
                  onClick={() => setProgramDialogOpen(true)}
                  endIcon={article?.programmedAt ? <TimerOutlinedIcon /> : null}
                >
                  {article?.programmedAt ? 'Reprogrammer' : 'Programmer'}
                </Button>
                <Button
                  size="small"
                  style={{ marginLeft: '8px', minWidth: '120px' }}
                  variant="contained"
                  color="success"
                  disabled={!canSubmit() || article?.status === 2}
                  onClick={() => setNotificationDialogOpen(true)}
                >
                  Publier
                </Button>
              </div>
              <div style={{ display: 'flex' }}>
                <Button
                  size="small"
                  style={{ minWidth: '120px' }}
                  variant="contained"
                  color="warning"
                  onClick={() => {
                    if (article) {
                      if (
                        window.confirm(
                          'Vos modifications seront automatiquement publiée ! Validez-vous cette action ?',
                        )
                      ) {
                        onSubmit('draft')
                      }
                    } else {
                      onSubmit('draft')
                    }
                  }}
                >
                  {article ? 'Mettre à jour' : 'Enregistrer'}
                </Button>
                <Button
                  size="small"
                  style={{ marginLeft: '8px', minWidth: '120px' }}
                  variant="contained"
                  startIcon={<DeleteIcon />}
                  color="error"
                  onClick={() => onDelete()}
                >
                  Supprimer
                </Button>
              </div>
            </Paper>
            <Paper className="article-actions-container">
              <h4>Notifications</h4>
              <FormControlLabel
                label="Activer la notification"
                control={
                  <CheckBox
                    size="small"
                    checked={notificationChecked}
                    onChange={(e) => setNotificationChecked(e.target.checked)}
                  />
                }
              />
            </Paper>
            <Paper className="article-actions-container">
              <h4>
                Paramétrages concessions
                <GroupIcon style={{ marginLeft: '6px' }} />
              </h4>
              <DealershipSelector
                brands={props.brands}
                selectedDealerships={selectedDealerships}
                selectedBrands={selectedBrands}
                grouped={grouped}
                onGroupCheck={onGroupCheck}
                onBrandCheck={onBrandCheck}
                onDealershipCheck={onDealershipCheck}
                enabledBrands={props.user?.brands || []}
                enableGroup={!!props.user?.group}
                size="small"
              />
            </Paper>
            <Paper className="article-actions-container">
              <h4>
                Paramétrages des fonctions
                <GroupIcon style={{ marginLeft: '6px' }} />
              </h4>
              <FormControl component="legend">
                <RadioGroup value={office}>
                  {props.offices.map((off) => (
                    <FormControlLabel
                      key={off._id}
                      value={off.level}
                      control={
                        <Radio
                          size="small"
                          classes={
                            office > off.level ? { root: 'Mui-disabled' } : {}
                          }
                        />
                      }
                      label={off.name}
                      checked={office >= off.level}
                      onClick={() => setOffice(parseInt(off.level))}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </Paper>
          </div>
        </>
      )}
      <ProgramDialog
        open={programDialogOpen}
        handleClose={() => {
          setProgramDialogOpen(false)
          setNewProgram(null)
        }}
        onProgram={onProgram}
        defaultDate={article?.programmedAt}
      />
      <NotificationDialog
        open={notificationDialogOpen}
        handleClose={() => {
          setNotificationDialogOpen(false)
          setNewProgram(null)
        }}
        onFinish={(image, youtube, title, text) => {
          onSubmit(
            newProgram ? 'program' : 'publish',
            image,
            youtube,
            title,
            text,
          )
        }}
        notificationChecked={notificationChecked}
        defaultImage={
          notificationChecked
            ? article?.notification?.image
            : article?.thumbnail
        }
        defaultTitle={article?.notification?.title}
        defaultText={article?.notification?.description}
      />
      <PreviewDialog
        open={!!previewDialog}
        handleClose={() => setPreviewDialog(null)}
        content={previewDialog}
      />
      {/* <ToastContainer theme="colored" bodyClassName="toast-body" /> */}
      <Crop
        onSubmit={(crop) => props.setCrop(true, crop)}
        imageUrl={props.cropImageUrl}
        open={props.cropDialogOpen}
        onClose={() => props.setCrop(false)}
        defaultCrop={{
          unit: 'px',
          width: 200,
          height: 200,
          x: 30,
          y: 30,
        }}
        imageWidth={'700px'}
      />
    </Page>
  )
}

const mapStateToProps = (state: any) => {
  return {
    brands: state.settings.mergedBrands,
    offices: state.settings.offices,
    cropDialogOpen: state.crop.show,
    cropImageUrl: state.crop.imageUrl,
    user: state.auth.user,
  }
}

const mapDispatchToProps = { setCrop }

export default connect(mapStateToProps, mapDispatchToProps)(ArticleView)
