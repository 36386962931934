import { combineReducers } from 'redux'
import _ from 'lodash'

function auth(
  state: any = {
    token: null,
    user: null,
  },
  action: any,
) {
  switch (action.type) {
    case 'LOGIN': {
      return {
        ...state,
        token: action.token,
        user: action.user,
      }
    }
    case 'LOGOUT': {
      return {
        ...state,
        token: false,
        user: false,
      }
    }
    case 'UPDATE_USER': {
      return {
        ...state,
        user: action.user,
      }
    }
    default: {
      return state
    }
  }
}

function settings(
  state: any = {
    brands: [],
    offices: [],
    dealerships: [],
    mergedBrands: [],
  },
  action: any,
) {
  switch (action.type) {
    case 'UPDATE_SETTINGS': {
      const offices = action.offices || state.offices
      const brands = action.brands || state.brands
      const dealerships = action.dealerships || state.dealerships
      const mergedBrands = brands.map((brand) => ({
        ...brand,
        dealerships: dealerships.filter(
          (dealership) => !!dealership.brands.find((id) => id === brand._id),
        ),
      }))

      return {
        ...state,
        offices,
        brands,
        dealerships,
        mergedBrands,
      }
    }
    case 'SET_BRAND_COLOR': {
      let newBrands = _.cloneDeep(state.brands)

      let brand = newBrands.find((v) => v._id === action.id)

      if (brand) {
        brand.color = action.color
      }

      return {
        ...state,
        brands: newBrands,
      }
    }
    default: {
      return state
    }
  }
}

function crop(
  state: any = {
    show: false,
    value: null,
    imageUrl: '',
  },
  action: any,
) {
  switch (action.type) {
    case 'SET_CROP': {
      return {
        ...state,
        show: !!action.show,
        value: action.value !== undefined ? action.value : state.value,
        imageUrl:
          action.imageUrl !== undefined ? action.imageUrl : state.imageUrl,
      }
    }
    default: {
      return state
    }
  }
}

const reducers = combineReducers({
  auth,
  settings,
  crop,
})

export default reducers
