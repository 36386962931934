import { Navigate } from 'react-router-dom'
import DashboardLayout from 'layouts/DashboardLayout'
import MainLayout from 'layouts/MainLayout'
import LoginView from 'views/LoginView'
import ResetPasswordView from 'views/ResetPasswordView'
import UsersView from 'views/UsersView'
import HomeView from 'views/HomeView'
import ArticleView from 'views/ArticleView'
import SettingsView from 'views/SettingsView'
import RequestsView from 'views/RequestsView'

const pageTitles = {
  '/': "Bienvenue dans l'univers du Groupe Jean Rouyer.",
  '/article': "Création d'un nouvel article",
  '/article/.*': "Modification d'un article",
  '/users': 'Gestion des utilisateurs',
  '/requests': 'Gestion des demandes',
  '/settings': "Paramètrage de l'application",
}

const routes = (isLoggedIn: boolean) => [
  {
    path: '/auth',
    element: !isLoggedIn ? <MainLayout /> : <Navigate to="/" />,
    children: [
      { path: 'login', element: <LoginView /> },
      { path: 'reset', element: <ResetPasswordView /> },
      { path: '/auth', element: <Navigate to="/auth/login" /> },
    ],
  },
  {
    path: '/',
    element: isLoggedIn ? (
      <DashboardLayout pageTitles={pageTitles} />
    ) : (
      <Navigate to="/auth/login" />
    ),
    children: [
      { path: '/', element: <HomeView /> },
      { path: '/article', element: <ArticleView /> },
      { path: '/article/:id', element: <ArticleView /> },
      { path: '/users', element: <UsersView /> },
      { path: '/requests', element: <RequestsView /> },
      { path: '/settings', element: <SettingsView /> },
      { path: '404', element: null },
      { path: '*', element: <Navigate to="/404" /> },
    ],
  },
]

export default routes
