import { Outlet } from 'react-router-dom'
import NavBar from './NavBar'
import { connect } from 'react-redux'
import { logout } from 'state/actions'

import './DashboardLayout.css'

const DashboardLayout = (props: any) => {
  return (
    <div className="dasboard-layout-root">
      <NavBar
        logout={() => {
          localStorage.removeItem('token')
          props.logout()
        }}
        pageTitles={props.pageTitles}
      >
        <Outlet />
        {props.children}
      </NavBar>
    </div>
  )
}

const mapDispatchToProps = { logout }

export default connect(null, mapDispatchToProps)(DashboardLayout)
