export function login(token: string, user: any) {
  return {
    type: 'LOGIN',
    token,
    user,
  }
}

export function logout() {
  return {
    type: 'LOGOUT',
  }
}

export function updateUser(user) {
  return {
    type: 'UPDATE_USER',
    user
  }
}


export function updateSettings(offices: any, brands: any, dealerships: any) {
  return {
    type: 'UPDATE_SETTINGS',
    offices,
    brands,
    dealerships
  }
}

export function setBrandColor(id: string, color: string) {
  return {
    type: 'SET_BRAND_COLOR',
    id,
    color
  }
}

export function setCrop(show: boolean, value: any, imageUrl: string) {
  return {
    type: 'SET_CROP',
    show,
    value,
    imageUrl
  }
}
