import axios from 'axios'
import moment from 'moment'
import 'moment/locale/fr'
import { useEffect } from 'react'
import { connect } from 'react-redux'
import { useRoutes } from 'react-router-dom'
import routes from 'routes'
import { login, logout, updateSettings } from 'state/actions'
import './App.css'

moment.locale('fr')

function App({ onLogin, onLogout, onUpdateSettings, ...props }: any) {
  const getSettings = async () => {
    try {
      const [officeResponse, brandResponse, dealershipResponse] =
        await Promise.all([
          axios.get('/admin/office'),
          axios.get('/admin/brand'),
          axios.get('/admin/dealership'),
        ])

      return {
        offices: officeResponse?.data?.offices,
        brands: brandResponse?.data?.brands,
        dealerships: dealershipResponse?.data?.dealerships,
      }
    } catch (err) {
      console.error(err)
    }
  }

  const getUser = async () => {
    try {
      let response = await axios.get('/auth/account')

      return response?.data
    } catch (err) {
			localStorage.removeItem('token')
      console.error(err)
    }
  }

  useEffect(() => {
    let token = localStorage.getItem('token')

    if (!token) {
      onLogout()
    } else {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
      getSettings()
        .then((settings) => {
          if (settings) {
            onUpdateSettings(
              settings.offices,
              settings.brands,
              settings.dealerships,
            )
          }

          getUser().then((user) => {
            onLogin(token, user)
          })
        })
        .catch((err) => console.error(err))
    }
  }, [onLogin, onLogout, onUpdateSettings])

  const routing = useRoutes(routes(!!props.token))

  // Return loading screen instead of null if auth is loading
  return props.token !== null ? routing : null
}

const mapStateToProps = (state: any) => {
  return {
    token: state.auth.token,
  }
}

const mapDispatchToProps = {
  onLogin: login,
  onLogout: logout,
  onUpdateSettings: updateSettings,
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
