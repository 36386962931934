import Page from 'components/Page'
import Offices from './Offices'
import Dealerships from './Dealerships'
import './SettingsView.css'
import Colors from './Colors'


const SettingsView = (props: any) => {

  return (
    <Page title="Paramètrage" className="settings-container">
			<div className='row1'>
      	<div>
					<Offices />
					<Colors />
				</div>
      	<Dealerships />
			</div>
    </Page>
  )
}

export default SettingsView
