export class ClickableLink {
  constructor(quill) {
    quill.container.addEventListener('mouseover', (evt) => {
      if (evt.target.tagName === 'A') {
        evt.target.setAttribute('contenteditable', false)
      } else if (
        evt.target.tagName === 'P' &&
        evt.target.children[0]?.tagName === 'A'
      ) {
        evt.target.children[0].setAttribute('contenteditable', false)
      }
    })
  }
}
