import React, { useState } from 'react'
import Page from 'components/Page'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import axios from 'axios'

import './ResetPasswordView.css'

const ResetPasswordView = (props: any) => {
  const [email, setEmail] = useState('')

  const onSubmit = async (e: any) => {
    e.preventDefault()

    try {
      await axios.post('/auth/password/reset', { email })

      toast.success('Votre nouveau mot de passe vous a été envoyé par email')
    } catch (err: any) {
      console.error(err)
      if (err?.response?.data?.error?.message) {
        toast.error(err?.response?.data?.error?.message)
      } else {
        toast.error('Erreur serveur, opération impossible')
      }
    }
  }

  return (
    <Page title="Mot de passe oublié" className="passwd-container">
      <div className="passwd-form-container">
        <h1>MOT DE PASSE OUBLIÉ</h1>
        <p>Indiquez l'adresse email associée à votre compte</p>
        <form onSubmit={onSubmit}>
          <TextField
            className="text-input"
            fullWidth
            label="Adresse email"
            variant="standard"
            InputLabelProps={{
              style: {
                color: '#43425D',
                fontSize: 16,
              },
              required: false,
            }}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            type="email"
          />
          <div className="passwd-form-row">
            <span />
            <Link to="/">Se connecter</Link>
          </div>
          <Button type="submit" variant="contained">
            Valider
          </Button>
        </form>
      </div>
      {/* <ToastContainer theme="colored" bodyClassName="toast-body" /> */}
    </Page>
  )
}

export default ResetPasswordView
