import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import SaveIcon from '@mui/icons-material/Save'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Paper from '@mui/material/Paper'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import { updateSettings } from 'state/actions'
import './SettingsView.css'


const Offices = (props: any) => {
	const [items, setItems] = useState<any[]>(null)
	const [dirty, setDirty] = useState<boolean>(false)

	useEffect(() => {
		setItems(props.offices)
	}, [props.offices])

	const onOfficesSubmit = async () => {
		try {
			const response = await axios.put('/admin/office', {
				offices: items,
			})

			if (response?.data?.offices) {
				props.updateSettings(response.data.offices, null, null)
			}
		} catch (err: any) {
			console.error(err)
			if (err?.response?.data?.error?.message) {
				toast.error(err.response.data.error.message)
			} else {
				toast.error('Erreur serveur, opération impossible')
			}
		} finally {
			setDirty(false)
		}
	}

	const reorder = (list: Array<any>, startIndex: number, endIndex: number) => {
		const result = Array.from(list)
		const [removed] = result.splice(startIndex, 1)
		result.splice(endIndex, 0, removed)

		return result
	}

	const onDragEnd = (result: any) => {
		if (!result.destination) {
			return
		}

		setItems(reorder(items, result.source.index, result.destination.index))

		setDirty(true)
	}

	const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
		display: 'flex',
		alignItems: 'center',
		userSelect: 'none',
		padding: '16px 16px 16px 6px',
		margin: `0 0 10px 0`,

		background: isDragging ? '#ffffff' : '#ffffff',

		...draggableStyle,
	})

	const onItemNameChange = (id: string, key: string) => (e) => {
		const newItems = [...items]

		newItems.find((v) => (v._id ? v._id === id : v.key === key)).name =
			e.target.value

		setItems(newItems)
		setDirty(true)
	}

	const onAddItem = () => {
		const newItem = {
			key: Math.max(Math.random(), 0.000001).toString(36).substring(2, 9),
			_id: null,
			name: '',
		}

		console.log(newItem)

		setItems([...items, newItem])
		setDirty(true)
	}

	const onDeleteItem = (id, key) => {
		if (window.confirm('Êtes-vous sûr de vouloir supprimer cette fonction ?')) {
			setItems(items.filter((v) => (v._id ? v._id !== id : v.key !== key)))
			setDirty(true)
		}
	}

	return (
		<div>
			<div style={{ display: 'flex', alignItems: 'center', width: '336px' }}>
				<h2>Fonctions</h2>
				<Button
					size="small"
					style={{ marginLeft: '14px' }}
					variant="contained"
					startIcon={<AddIcon />}
					onClick={onAddItem}
				>
					Ajouter
				</Button>
			</div>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'flex-end',
				}}
			>
				<DragDropContext onDragEnd={onDragEnd}>
					<Droppable droppableId="droppable">
						{(provided: any, snapshot: any) => (
							<div {...provided.droppableProps} ref={provided.innerRef}>
								{items?.map((item: any, index: number) => (
									<Draggable
										key={item._id || item.key}
										draggableId={item._id || item.key}
										index={index}
									>
										{(provided, snapshot) => (
											<Paper
												ref={provided.innerRef}
												{...provided.draggableProps}
												{...provided.dragHandleProps}
												style={getItemStyle(
													snapshot.isDragging,
													provided.draggableProps.style,
												)}
												className="office-box"
											>
												<IconButton
													onClick={() => onDeleteItem(item._id, item.key)}
													className="delete-btn"
												>
													<DeleteIcon />
												</IconButton>
												<input
													type="text"
													placeholder="Nom de la fonction"
													style={{ fontFamily: 'Roboto' }}
													value={item.name}
													onChange={onItemNameChange(item._id, item.key)}
													className="office-name-input"
													autoComplete="none"
												/>
												<DragIndicatorIcon />
											</Paper>
										)}
									</Draggable>
								))}
								{provided.placeholder}
							</div>
						)}
					</Droppable>
				</DragDropContext>
				<Button
					size="small"
					variant="contained"
					startIcon={<SaveIcon />}
					onClick={onOfficesSubmit}
					disabled={!dirty}
				>
					Enregistrer
				</Button>
			</div>
		</div>
	)
}

const mapStateToProps = (state: any) => {
	return {
		offices: state.settings.offices,
	}
}

const mapDispatchToProps = { updateSettings }

export default connect(mapStateToProps, mapDispatchToProps)(Offices)
