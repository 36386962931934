import { useState, useEffect } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import TextField from '@mui/material/TextField'
import IconButton from '@mui/material/IconButton'
import EditIcon from '@mui/icons-material/Edit'
import CircularProgress from '@mui/material/CircularProgress'
import axios from 'axios'
import Crop from 'components/Crop'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'
import config from 'config'

import 'react-image-crop/dist/ReactCrop.css'
import './ArticleView.css'

const NotificationDialog = (props: any) => {
  const [notificationImage, setNotificationImage] = useState(null)
  const [notificationYoutube, setNotificationYoutube] = useState(null)
  const [notificationTitle, setNotificationTitle] = useState('')
  const [notificationText, setNotificationText] = useState('')

  const [loading, setLoading] = useState(false)

  const [imageFile, setImageFile] = useState('')
  const [imageUrl, setImageUrl] = useState('')
  const [cropDialogOpen, setCropDialogOpen] = useState(false)

  const cancel = () => {
    props.handleClose()
    setNotificationImage(props.defaultImage)
    setNotificationYoutube(props.defaultYoutube || '')
    setNotificationTitle(props.defaultTitle || '')
    setNotificationText(props.defaultText || '')
  }

  useEffect(() => {
    setNotificationImage(props.defaultImage)
    setNotificationYoutube(props.defaultYoutube || '')
    setNotificationTitle(props.defaultTitle || '')
    setNotificationText(props.defaultText || '')
  }, [props.defaultImage, props.defaultYoutube, props.defaultText])

  const onFileChange = async (event: any) => {
    if (event?.target?.files[0]) {
      setImageFile(event.target.files[0])
      setImageUrl(URL.createObjectURL(event.target.files[0]))
      setCropDialogOpen(true)
      event.target.value = null
    }
  }

  const onCropSubmit = async (crop) => {
    try {
      setLoading(true)
      const formData = new FormData()

      formData.append('file', imageFile)
      formData.append(
        'body',
        JSON.stringify({
          resize: {
            width: 701,
          },
          crop: {
            top: Math.round(crop.y),
            left: Math.round(crop.x),
            width: Math.round(crop.width),
            height: Math.round(crop.height),
          },
        }),
      )

      const res = await axios.put('/upload/image', formData)
      if (res.data.file) {
        setNotificationImage(res.data.file)
        setCropDialogOpen(false)
      }
    } catch (err) {
      console.error(err)
      if (err?.response?.data?.error?.message) {
        toast.error(err.response.data.error.message)
      } else {
        toast.error('Erreur serveur, opération impossible')
      }
    } finally {
      setLoading(false)
    }
  }

  function parseYoutubeUrl(url: string) {
    const regex = new RegExp(
      /(^|[\s])(https?\:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/watch(\/)?(\?){1}[^\s\?]*/,
    )
    const matchs = url.match(regex)

    if (matchs === null) return null

    const match = matchs[0]
    const uri = new URL(match)

    const query = uri.searchParams

    if (!query.has('v')) return null

    const id = query.get('v')

    return {
      preview: `https://img.youtube.com/vi/${id}/sddefault.jpg`,
      id: id,
    }
  }

  return (
    <Dialog
      open={props.open}
      onClose={cancel}
      fullWidth
      maxWidth="md"
      scroll="body"
    >
      <DialogTitle>
        {props.notificationChecked
          ? `Paramètrer la notification`
          : `Définir la miniature`}
      </DialogTitle>
      <DialogContent dividers>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around',
          }}
        >
          <div className="notification-setting-container">
            <h4>
              {props.notificationChecked
                ? `Image de la notification :`
                : `Miniature de l'article :`}
            </h4>
            <div
              className="notification-image-container"
              style={{
                background: notificationImage
                  ? `center / cover no-repeat url("${config.API_URL}${notificationImage}")`
                  : 'lightgray',
              }}
            >
              <label className="edit-btn" htmlFor="icon-button-file">
                <input
                  accept="image/*"
                  id="icon-button-file"
                  type="file"
                  onChange={onFileChange}
                />
                <IconButton
                  disabled={loading}
                  color="primary"
                  size="large"
                  component="span"
                >
                  <EditIcon />
                </IconButton>
              </label>
              {loading && <CircularProgress />}
            </div>
            <p
              style={{
                margin: 0,
                marginTop: '8px',
                color: '#0000005f',
                fontSize: '13px',
              }}
            >
              1600px x 1200px .png ou .jpeg
            </p>
            <h4>URL Youtube :</h4>

            <TextField
              variant="outlined"
              autoComplete="none"
              required
              value={notificationYoutube}
              onChange={(e) => setNotificationYoutube(e.target.value)}
            />
          </div>
          {props.notificationChecked && (
            <div className="notification-setting-container">
              <h4>Titre de la notification :</h4>
              <TextField
                variant="outlined"
                style={{ width: '300px' }}
                autoComplete="none"
                required
                inputProps={{ maxLength: 70 }}
                helperText={`${notificationTitle.length}/70`}
                value={notificationTitle}
                onChange={(e) => setNotificationTitle(e.target.value)}
              />
              <h4>Texte de la notification :</h4>
              <TextField
                variant="outlined"
                style={{ width: '300px' }}
                multiline
                rows={4}
                autoComplete="none"
                required
                inputProps={{ maxLength: 140 }}
                helperText={`${notificationText.length}/140`}
                value={notificationText}
                onChange={(e) => setNotificationText(e.target.value)}
              />
            </div>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={cancel} color="inherit">
          Fermer
        </Button>
        <Button
          disabled={
            loading ||
            (props.notificationChecked &&
              (!notificationTitle || !notificationText)) ||
            (!notificationImage && !notificationYoutube)
          }
          onClick={() => {
            var parsedUrl
            if (notificationYoutube.length != 0) {
              parsedUrl = parseYoutubeUrl(notificationYoutube)

              if (parsedUrl == null) {
                toast.warn('Video youtube non valide')
                return
              }
            }

            console.log(parsedUrl)

            return props.onFinish(
              notificationImage,
              parsedUrl,
              notificationTitle,
              notificationText,
            )
          }}
          color="primary"
        >
          TERMINER
        </Button>
      </DialogActions>
      <Crop
        loading={loading}
        onSubmit={onCropSubmit}
        imageUrl={imageUrl}
        open={cropDialogOpen}
        onClose={() => setCropDialogOpen(false)}
        imageWidth={'700px'}
        defaultCrop={{
          unit: 'px',
          aspect: 4 / 3,
          width: 240,
          x: 30,
          y: 30,
        }}
      />
    </Dialog>
  )
}

NotificationDialog.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  onFinish: PropTypes.func,
  notificationChecked: PropTypes.bool,
  defaultTitle: PropTypes.any,
  defaultImage: PropTypes.any,
  defaultYoutube: PropTypes.any,
  defaultText: PropTypes.any,
}

NotificationDialog.defaultProps = {
  open: false,
  handleClose: () => null,
  onFinish: () => null,
  notificationChecked: false,
  defaultTitle: null,
  defaultImage: '',
  defaultYoutube: null,
  defaultText: '',
}

export default NotificationDialog
