import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TextField from '@mui/material/TextField'
import Paper from '@mui/material/Paper'
import axios from 'axios'
import { useRef, useState } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import { updateSettings } from 'state/actions'
import './SettingsView.css'

const PromptDialog = ({ open, title, placeholder, onSubmit, onClose }) => {
  const [dialogInputValue, setDialogInputValue] = useState('')

  const handleDialogClose = () => {
    onClose()
    setDialogInputValue('')
  }

  return (
    <Dialog fullWidth open={open} onClose={handleDialogClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label={placeholder}
          type="text"
          fullWidth
          variant="standard"
          value={dialogInputValue}
          onChange={(e) => setDialogInputValue(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button color="inherit" onClick={handleDialogClose}>
          Annuler
        </Button>
        <Button
          onClick={() => {
            onSubmit(dialogInputValue)
            handleDialogClose()
          }}
        >
          Créer
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const Dealerships = (props: any) => {
  const [dialogOpen, setDialogOpen] = useState<any>(null)

  const tableContainer = useRef(null)

  //   useEffect(() => {}, [props.brands])

  const createBrand = async (name) => {
    try {
      await axios.post('/admin/brand', { name })

      let response = await axios.get('/admin/brand')
      props.updateSettings(null, response?.data?.brands, null)
    } catch (err: any) {
      console.error(err)
      if (err?.response?.data?.error?.message) {
        toast.error(err.response.data.error.message)
      } else {
        toast.error('Erreur serveur, opération impossible')
      }
    }
  }

  const createDealership = async (name) => {
    try {
      await axios.post('/admin/dealership', { name })

      let response = await axios.get('/admin/dealership')
      props.updateSettings(null, null, response?.data?.dealerships)
      const scroller = tableContainer!.current!
      scroller.scrollTop = scroller.scrollHeight - scroller.clientHeight
    } catch (err: any) {
      console.error(err)
      if (err?.response?.data?.error?.message) {
        toast.error(err.response.data.error.message)
      } else {
        toast.error('Erreur serveur, opération impossible')
      }
    }
  }

  const onDealershipAssigned = async (dealership, brandId) => {
    try {
      await axios.patch(`/admin/dealership/${dealership._id}`, {
        brands: [...dealership.brands, brandId],
      })

      let response = await axios.get('/admin/dealership')
      props.updateSettings(null, null, response?.data?.dealerships)
    } catch (err: any) {
      console.error(err)
      if (err?.response?.data?.error?.message) {
        toast.error(err.response.data.error.message)
      } else {
        toast.error('Erreur serveur, opération impossible')
      }
    }
  }

  const onDealershipUnassigned = async (dealership, brandId) => {
    try {
      await axios.patch(`/admin/dealership/${dealership._id}`, {
        brands: dealership.brands.filter((v) => v !== brandId),
      })

      let response = await axios.get('/admin/dealership')
      props.updateSettings(null, null, response?.data?.dealerships)
    } catch (err: any) {
      console.error(err)
      if (err?.response?.data?.error?.message) {
        toast.error(err.response.data.error.message)
      } else {
        toast.error('Erreur serveur, opération impossible')
      }
    }
  }

  const onBrandDelete = async (brandId) => {
		if (window.confirm('Êtes-vous sûr de vouloir supprimer cette plaque ?')) {
			try {
				await axios.delete(`/admin/brand/${brandId}`)
	
				let response = await axios.get('/admin/brand')
				props.updateSettings(null, response?.data?.brands, null)
			} catch (err: any) {
				console.error(err)
				if (err?.response?.data?.error?.message) {
					toast.error(err.response.data.error.message)
				} else {
					toast.error('Erreur serveur, opération impossible')
				}
			}
		}
  }

  const onDealershipDelete = async (dealershipId) => {
		if (window.confirm('Êtes-vous sûr de vouloir supprimer cette concession ?')) {
			try {
				await axios.delete(`/admin/dealership/${dealershipId}`)
	
				let response = await axios.get('/admin/dealership')
				props.updateSettings(null, null, response?.data?.dealerships)
			} catch (err: any) {
				console.error(err)
				if (err?.response?.data?.error?.message) {
					toast.error(err.response.data.error.message)
				} else {
					toast.error('Erreur serveur, opération impossible')
				}
			}
		}
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        flex: '1',
        marginLeft: '30px',
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <h2>Plaques & Concessions</h2>
        <Button
          size="small"
          style={{ marginLeft: '14px' }}
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => setDialogOpen('brand')}
        >
          Ajouter une plaque
        </Button>
        <Button
          size="small"
          style={{ marginLeft: '14px' }}
          variant="contained"
          color="secondary"
          startIcon={<AddIcon />}
          onClick={() => setDialogOpen('dealership')}
        >
          Ajouter une concession
        </Button>
      </div>
      <TableContainer
        style={{ scrollBehavior: 'smooth' }}
        ref={tableContainer}
        sx={{ maxHeight: 'calc(100vh - 250px)' }}
				component={Paper}
      >
        <Table stickyHeader sx={{ minWidth: 750 }} size="small">
          <TableHead>
            <TableRow>
              <TableCell>-</TableCell>
              {props.brands.map((brand) => (
                <TableCell
                  className="table-header-cell"
                  align="center"
                  key={brand._id}
                >
                  {brand.name}
                  <IconButton
                    size="small"
                    onClick={() => onBrandDelete(brand._id)}
                    className="table-delete-btn"
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.dealerships.map((dealership) => (
              <TableRow key={dealership._id}>
                <TableCell className="table-body-cell">
                  {dealership.name}
                  <IconButton
                    size="small"
                    onClick={() => onDealershipDelete(dealership._id)}
                    className="table-delete-btn"
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
                {props.brands.map((brand) => (
                  <TableCell align="center" key={brand._id}>
                    <Checkbox
                      checked={dealership.brands.includes(brand._id)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          onDealershipAssigned(dealership, brand._id)
                        } else {
                          onDealershipUnassigned(dealership, brand._id)
                        }
                      }}
                      color="primary"
                    />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <PromptDialog
        open={dialogOpen === 'brand'}
        title="Ajouter une plaque"
        placeholder="Nom de la plaque"
        onClose={() => setDialogOpen(null)}
        onSubmit={createBrand}
      />
      <PromptDialog
        open={dialogOpen === 'dealership'}
        title="Ajouter une concession"
        placeholder="Nom de la concession"
        onClose={() => setDialogOpen(null)}
        onSubmit={createDealership}
      />
    </div>
  )
}

const mapStateToProps = (state: any) => {
  return {
    brands: state.settings.brands,
    dealerships: state.settings.dealerships,
  }
}

const mapDispatchToProps = { updateSettings }

export default connect(mapStateToProps, mapDispatchToProps)(Dealerships)
