import PropTypes from 'prop-types'
import { ReactNode, useState } from 'react'
import {
  Box,
  Drawer,
  Toolbar,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Avatar,
} from '@mui/material'
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import { styled } from '@mui/material/styles'
import HomeIcon from '@mui/icons-material/Home'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import PersonIcon from '@mui/icons-material/Person'
import SettingsIcon from '@mui/icons-material/Settings'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import MenuIcon from '@mui/icons-material/Menu'
import DoneIcon from '@mui/icons-material/Done'
import { useLocation, Link } from 'react-router-dom'
import LogoutIcon from '@mui/icons-material/Logout'
import { connect } from 'react-redux'
import getInitials from 'helpers/getInitials'

import './NavBar.css'
import { imgurl } from 'helpers/imageUrl'

interface NavItems {
  href: string
  icon?: ReactNode
  title: string
}

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}))

interface AppBarProps extends MuiAppBarProps {
  open?: boolean
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

const drawerWidth = 240

const NavBar = (props: any) => {
  const location = useLocation()

  const [open, setOpen] = useState(true)

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  const items: Array<NavItems> = [
    {
      href: '/',
      icon: <HomeIcon />,
      title: 'Tous les articles',
    },
    {
      href: '/article',
      icon: <AttachFileIcon />,
      title: 'Créer un article',
    },
    {
      href: '/users',
      icon: <PersonIcon />,
      title: 'Utilisateurs',
    },
    {
      href: '/requests',
      icon: <DoneIcon />,
      title: 'Demandes',
    },
  ]

  if (props.user?.role === 3) {
    items.push({
      href: '/settings',
      icon: <SettingsIcon />,
      title: 'Paramètrage',
    })
  }

  const content = (
    <List>
      {items.map((item, index) => (
        <Link to={item.href} key={index}>
          <ListItem
            selected={
              (location.pathname.startsWith(item.href) && item.href !== '/') ||
              (item.href === '/' && location.pathname === '/')
            }
            button
            classes={{
              root: 'nav-item',
              selected: 'nav-item-selected',
            }}
          >
            {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
            <ListItemText primary={item.title} />
          </ListItem>
        </Link>
      ))}
    </List>
  )

  let pageTitle = "Bienvenue dans l'univers du Groupe Jean Rouyer."
  for (let key in props.pageTitles) {
    if (new RegExp(`^${key}$`).test(location.pathname)) {
      pageTitle = props.pageTitles[key]
      break
    }
  }

  return (
    <Box sx={{ display: 'flex', paddingTop: '70px' }}>
      <AppBar classes={{ root: 'appbar' }} position="fixed" open={open}>
        <Toolbar classes={{ root: 'toolbar' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <IconButton
              color="inherit"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <h2 className="appbar-title">{pageTitle}</h2>
          </div>
          <div className="app-bar-avatar-container">
            <h3>{`${props.user?.firstname} ${props.user?.lastname}`}</h3>
            <Avatar alt="Avatar" src={imgurl(props.user?.avatar)}>
              <span style={{ fontSize: '13px' }}>
                {getInitials(
                  `${props.user?.firstname} ${props.user?.lastname}`,
                )}
              </span>
            </Avatar>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: 'drawer',
        }}
      >
        <div className="drawer-header">
          <IconButton
            style={{ alignSelf: 'flex-end' }}
            onClick={handleDrawerClose}
          >
            <ChevronLeftIcon />
          </IconButton>
          <img src="/assets/logo-jra.png" alt="Logo" title="Logo" />
        </div>
        {content}
        <ListItem
          button
          classes={{
            root: 'nav-item-footer',
          }}
          onClick={props.logout}
        >
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText primary="Se déconnecter" />
        </ListItem>
      </Drawer>
      <Main open={open}>{props.children}</Main>
    </Box>
  )
}

NavBar.propTypes = {
  children: PropTypes.node,
  logout: PropTypes.func,
  pageTitles: PropTypes.object,
}

NavBar.defaultProps = {
  children: null,
  logout: () => null,
  pageTitles: {},
}

const mapStateToProps = (state: any) => {
  return {
    user: state.auth.user,
  }
}

export default connect(mapStateToProps, null)(NavBar)
