import { useState, useEffect } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import PropTypes from 'prop-types'
import TextField from '@mui/material/TextField'
import StaticDatePicker from '@mui/lab/StaticDatePicker'
import StaticTimePicker from '@mui/lab/StaticTimePicker'
import moment from 'moment'

import './ArticleView.css'

const ProgramDialog = (props: any) => {
  const [date, setDate] = useState<moment.Moment>(null)
  const [time, setTime] = useState<moment.Moment>(null)

  const setDefault = (date) => {
    if (date) {
      setDate(moment(date))
      setTime(moment(date))
    } else {
      setDate(null)
      setTime(null)
    }
  }

  useEffect(() => {
    setDefault(props.defaultDate)
  }, [props.defaultDate])

  const cancel = () => {
    props.handleClose()
    setDefault(props.defaultDate)
  }

  const onProgram = () => {
    let programDate = moment(date).set({
      hour: time.hour(),
      minutes: time.minutes(),
    })
    props.onProgram(programDate)
  }

  return (
    <Dialog
      open={props.open}
      onClose={cancel}
      scroll="body"
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>Programmer la publication</DialogTitle>
      <DialogContent dividers>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around',
          }}
        >
          <StaticDatePicker
            displayStaticWrapperAs="desktop"
            openTo="day"
            value={date}
						disablePast
            onChange={(newValue) => {
              setDate(newValue)
            }}
            renderInput={(params) => <TextField {...params} />}
          />
          <StaticTimePicker
            displayStaticWrapperAs="mobile"
            value={time}
            onChange={(newValue) => {
              setTime(newValue)
            }}
            renderInput={(params) => <TextField {...params} />}
            toolbarTitle="Heure de publication :"
            minTime={date?.isSame(moment(), 'day') ? moment() : null}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={cancel} color="inherit">
          Fermer
        </Button>
        <Button
          disabled={date === null || time === null}
          color="primary"
          onClick={onProgram}
        >
          PROGRAMMER
        </Button>
      </DialogActions>
    </Dialog>
  )
}

ProgramDialog.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  onProgram: PropTypes.func,
  defaultDate: PropTypes.string,
}

ProgramDialog.defaultProps = {
  open: false,
  handleClose: () => null,
  onProgram: () => null,
  defaultDate: null,
}

export default ProgramDialog
