import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import PropTypes from 'prop-types'
import ReactQuill from 'react-quill'

import './ArticleView.css'

const PreviewDialog = (props: any) => {
  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      scroll="body"
      fullWidth
      maxWidth="xs"
    >
      {props.content && (
        <div className="article-preview">
          <ReactQuill defaultValue={props.content} theme="bubble" readOnly />
        </div>
      )}
      <DialogActions>
        <Button onClick={props.handleClose} color="inherit">
          Fermer
        </Button>
      </DialogActions>
    </Dialog>
  )
}

PreviewDialog.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  content: PropTypes.any,
}

PreviewDialog.defaultProps = {
  open: false,
  handleClose: () => null,
  content: null,
}

export default PreviewDialog
