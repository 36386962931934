import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import CircularProgress from '@mui/material/CircularProgress'
import Button from '@mui/material/Button'
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'

const Crop = (props: any) => {
  const [crop, setCrop] = useState<any>(props.defaultCrop)

  useEffect(() => {
    let timeout = null
    if (!props.open) {
      timeout = setTimeout(() => setCrop(props.defaultCrop), 1000)
    }

    return () => {
      clearInterval(timeout)
    }
  }, [props.open])

  return (
    <Dialog open={props.open} onClose={() => props.onClose()} maxWidth={props.imageWidth} >
      <ReactCrop
        circularCrop={props.circularCrop}
        imageStyle={{ width: props.imageWidth }}
        src={props.imageUrl}
        crop={crop}
        onChange={(newCrop) => setCrop(newCrop)}
      />
      <DialogActions>
        {!props.loading ? (
          <>
            <Button onClick={() => props.onClose()} color="inherit">
              Annuler
            </Button>
            <Button onClick={() => props.onSubmit(crop)} color="primary">
              Valider
            </Button>
          </>
        ) : (
          <CircularProgress size={35} />
        )}
      </DialogActions>
    </Dialog>
  )
}

Crop.propTypes = {
  style: PropTypes.object,
  loading: PropTypes.bool,
  onSubmit: PropTypes.func,
  defaultCrop: PropTypes.object,
  imageUrl: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  imageWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  circularCrop: PropTypes.bool,
}

Crop.defaultProps = {
  style: {},
  loading: false,
  onSubmit: () => null,
  defaultCrop: null,
  imageUrl: '',
  open: false,
  onClose: () => null,
  imageWidth: '500px',
  circularCrop: false
}

export default Crop
